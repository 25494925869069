import type { Advisor } from "@verde/entities";
import { Document } from "@verde/modules";
import { useAuthContext } from "@verde/modules";
import React, { useEffect, useMemo, useState } from "react";
import type { AdvisorBankDocumentsProps } from "./types";

function AdvisorBankDocuments({ onValidation }: AdvisorBankDocumentsProps) {
  const { user } = useAuthContext<Advisor>();
  const [isIdentityValid, setIsIdentityValid] = useState(false);
  const [isCompanyValid, setIsCompanyValid] = useState(false);
  const [isSelfieValid, setIsSelfieValid] = useState(false);

  const isCompanyPartner = useMemo(() => {
    return !!user?.cnpj;
  }, [user?.cnpj]);

  useEffect(() => {
    const isValidationSuccessful =
      isIdentityValid &&
      isSelfieValid &&
      (isCompanyPartner ? isCompanyValid : true);

    onValidation?.(isValidationSuccessful);
  }, [
    isIdentityValid,
    isSelfieValid,
    isCompanyValid,
    isCompanyPartner,
    onValidation,
  ]);

  return (
    <div className="grid gap-2 mb-6">
      <Document.IdentityUpload
        advisor_id={user?.id}
        onValidation={(valid) => {
          setIsIdentityValid(valid);
        }}
      />

      <Document.SelfieUpload
        advisor_id={user?.id}
        onValidation={(valid) => {
          setIsSelfieValid(valid);
        }}
      />

      {isCompanyPartner && (
        <Document.CompanyUpload
          advisor_id={user?.id}
          onValidation={(valid) => {
            setIsCompanyValid(valid);
          }}
        />
      )}
    </div>
  );
}

export default React.memo(AdvisorBankDocuments);
