import type { IAdvisor } from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import React, { type ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PATH_APP } from "routes/paths";

type GuestProtectProps = {
  children: ReactNode;
};

export default function GuestProtect({ children }: GuestProtectProps) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { isAuthenticated } = useAuthContext<IAdvisor>();

  if (isAuthenticated) {
    const continueAt = query.get("continue");
    return <Navigate to={continueAt || PATH_APP.root} />;
  }

  return <>{children}</>;
}
