import { CardPreview, Dialog } from "@verde/compose-ui";
import {
  type Advisor,
  DocumentKindV2,
  VERDE_WHATSAPP_LINK,
  useFetchAddress,
  useFetchBankAccount,
  useVerifyAdvisorMarketplace,
} from "@verde/entities";

import { CardBankIcon, EditIcon, ReturnIcon, SendIcon } from "@verde/icons";
import { BankAccount } from "@verde/modules";
import { useAuthContext } from "@verde/modules";
import { Alert, Button, Skeleton, Switch, Text } from "@verde/ui-core";
import { formatDate, useAlert } from "@verde/utils";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { create } from "zustand";
import AdvisorBankDocuments from "./advisor-bank-documents";
import type { UseAdvisorBankAccountValidator } from "./types";

export const useAdvisorBankAccountValidator =
  create<UseAdvisorBankAccountValidator>((set) => {
    return {
      open: false,
      toggle: () => {
        set((state) => ({ open: !state.open }));
      },
    };
  });

function AdvisorBankAccountValidator() {
  const { user, refetch } = useAuthContext<Advisor>();
  const [isDocumentsValid, setIsDocumentsValid] = useState(false);
  const { open, toggle } = useAdvisorBankAccountValidator();
  const alert = useAlert();

  const { mutateAsync: marketPlaceValidation, isLoading: isValidating } =
    useVerifyAdvisorMarketplace(user?.id);
  const { data, isLoading: isFetchingBankAccount } = useFetchBankAccount(
    "Advisor",
    user?.id,
  );

  const isLoading = useMemo(
    () => isFetchingBankAccount,
    [isFetchingBankAccount],
  );

  const isVerified = useMemo(
    () => data?.progressable?.is_done,
    [data?.progressable?.is_done],
  );

  const [isOpenBankDrawer, toggleBankDrawer] = useReducer(
    (value) => !value,
    false,
  );
  const [validatedAccepted, setValidatedAccepted] = useState(false);
  const [showDocumentsValidator, setShowDocumentsValidator] = useState(false);

  const bankAccountCardDescription = useMemo(() => {
    if (isVerified)
      return (
        <Text className="text-sm font-medium text-neutral-dark-1">
          Dados verificados
        </Text>
      );
    return (
      <Text className="text-sm font-medium text-feedback-error">
        Verifiques os dados
      </Text>
    );
  }, [isVerified]);

  const allowValidationAction = useMemo(() => {
    if (!isDocumentsValid && !validatedAccepted) return true;
    if (validatedAccepted && (!isDocumentsValid || !showDocumentsValidator))
      return false;

    return !isDocumentsValid;
  }, [isDocumentsValid, validatedAccepted, showDocumentsValidator]);

  const handleClose = useCallback(async () => {
    await refetch();
    toggle();
  }, [toggle, refetch]);

  const onValidation = useCallback(async () => {
    const allreadySend =
      user?.marketplace && !user?.marketplace?.verification_feedback;
    let canSend = true;
    if (allreadySend)
      canSend = await alert.execute({
        title: "Validação dados bancários",
        kind: "warning",
        description: `A validação já foi solicitada em ${formatDate(user?.marketplace?.created_at)}. Tem certeza que deseja enviar os dados para solicitar novamente? `,
        confirmText: "Sim, continuar para dados",
        cancelText: "Fechar",
      });

    if (canSend)
      await marketPlaceValidation().then(() => {
        alert.execute({
          title: "Dados enviados para validação",
          kind: "warning",
          description: (
            <Alert kind="warning">
              Aguarde até 5 para concluir a análise. Se necessário solicite a
              validação novamente ou entre em contato com seu
              <a
                className="font-semibold text-brand-green-dark hover:underline"
                href={VERDE_WHATSAPP_LINK}
              >
                gerente de relacionamento
              </a>
            </Alert>
          ),
          hideConfirmButton: true,
          cancelText: "Fechar",
        });

        handleClose();
      });
  }, [marketPlaceValidation, alert, user?.marketplace, handleClose]);

  return (
    <Dialog
      size="sm"
      open={open}
      onClose={handleClose}
      header={{
        title: "Validação dados bancários",
        icon: <CardBankIcon />,
        kind: "warning",
        size: "lg",
      }}
    >
      <div className="grid gap-2">
        {isLoading ? (
          <Skeleton />
        ) : (
          <CardPreview
            classNames={{ base: "mb-4" }}
            title="Dados da conta bancária"
            description={bankAccountCardDescription}
            icon={<CardBankIcon />}
            kind={isVerified ? "checked" : "alert"}
            kindPlacement="top-left"
            actions={{
              primary: {
                icon: showDocumentsValidator ? <ReturnIcon /> : <EditIcon />,
                onClick: showDocumentsValidator
                  ? () => {
                      setValidatedAccepted((current) => !current);
                      setShowDocumentsValidator((current) => !current);
                    }
                  : () => toggleBankDrawer(),
              },
            }}
          />
        )}
        <hr className="mb-2" />
        {!showDocumentsValidator && (
          <Switch
            loading={isLoading}
            layout="box"
            id="accept"
            disabled={!isVerified}
            label="Tudo pronto! Esses são os dados da conta que desejo receber"
            checked={validatedAccepted}
            onChange={() => setValidatedAccepted((current) => !current)}
          />
        )}

        {showDocumentsValidator && (
          <AdvisorBankDocuments
            onValidation={(value) => {
              setIsDocumentsValid(value);
            }}
          />
        )}

        <hr className="mb-2" />

        <Button
          icon={isDocumentsValid && showDocumentsValidator && <SendIcon />}
          onClick={async () => {
            isDocumentsValid && showDocumentsValidator
              ? await onValidation()
              : setShowDocumentsValidator(true);
          }}
          size="lg"
          disabled={allowValidationAction}
          loading={isValidating}
        >
          Salvar e continuar
        </Button>
      </div>

      <BankAccount.Drawer
        accountable="Advisor"
        accountableId={user?.id}
        onClose={toggleBankDrawer}
        open={isOpenBankDrawer}
        bankAccount={data}
        options={{
          financial_institution_kind: "iugu",
        }}
      />
    </Dialog>
  );
}

export default React.memo(AdvisorBankAccountValidator);
