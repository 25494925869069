import { Card, CardPreview, CellList } from "@verde/compose-ui";
import {
  type Advisor,
  useFetchAllWarranties,
  useFetchApprovedCredit,
  useFetchOneClient,
  useFetchOneProposal,
  useFetchOneRuralActivity,
  useFetchPropertiesTotalArea,
} from "@verde/entities";
import { DocumentIcon, ExternalLinkIcon, LightIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, Copyable, Skeleton, Text } from "@verde/ui-core";
import { formatToBRL, formatToCPF } from "@verde/utils";
import Wizard from "components/Wizard";
import { AdvisorBankAccountAlertDialog } from "components/advisor-bank-account";
import {
  WorkflowLoader,
  type WorkflowLoaderProps,
} from "components/workflow-loader";
import { motion } from "framer-motion";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { tv } from "tailwind-variants";
import Middleware from "./middleware";
import type {
  HandleNavigate,
  HandleNavigating,
  ReplacePathWithParams,
  RouteParams,
  RoutesMatcher,
  StateContext,
  UpdaterContext,
} from "./types";

export const WorkflowProposalStateContext = createContext<
  StateContext | undefined
>(undefined);

export const WorkflowProposalUpdaterContext = createContext<
  UpdaterContext | undefined
>(undefined);

export function useWorkflowProposalState() {
  const context = useContext(WorkflowProposalStateContext);
  if (!context) {
    throw new Error(
      "useWorkflowProposalState must be used within WorkflowProposalProvider.",
    );
  }

  return context;
}

export function useWorkflowProposalUpdater() {
  const context = useContext(WorkflowProposalUpdaterContext);
  if (!context) {
    throw new Error(
      "useWorkflowProposalUpdater must be used within WorkflowProposalProvider.",
    );
  }

  return context;
}

export const workflowProposalStyles = tv({
  slots: {
    base: "grid items-start gap-4 lg:grid-cols-[minmax(auto,_380px)_minmax(auto,_480px)_minmax(auto,_280px)]",
    aside: "top-2 lg:sticky",
    content: "order-3 lg:-order-none",
  },
});

export const animate = {
  page: {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  },
  transition: {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  },
};

const NAVIGATION_MOVEMENT_DELAY = 1000;

export default function WorkflowContractProvider() {
  const contextRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { user } = useAuthContext<Advisor>();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<RouteParams>();

  const [leftSide, setLeftSide] = useState<ReactNode | undefined>(undefined);
  const [rightSide, setRightSide] = useState<ReactNode | undefined>(undefined);
  const [navigatingProps, setNavigatingProps] = useState<
    WorkflowLoaderProps | undefined
  >(undefined);
  const [advisorBankAccountAlertOpen, toggleAdvisorBankAccountAlert] =
    useReducer((state) => !state, false);

  const proposal = useFetchOneProposal(Number(params?.id));
  const client = useFetchOneClient(proposal.data?.client_id);
  const ruralActivity = useFetchOneRuralActivity(proposal.data?.id);
  const areaFraming = useFetchPropertiesTotalArea(proposal.data?.client_id);
  const approvedCredit = useFetchApprovedCredit(proposal.data?.id);
  const warranties = useFetchAllWarranties(proposal.data?.id);

  const ruralActivityType = useMemo(() => {
    return ruralActivity.data?.type === "Agriculture"
      ? "AGRICULTURA"
      : ruralActivity.data?.harvest?.crop_cultivation?.slug;
  }, [ruralActivity.data]);

  const remainingAreas = useMemo(() => {
    return areaFraming.data && !areaFraming.isLoading
      ? areaFraming.data?.total_area_reported -
          areaFraming.data?.total_area_registered <
        0
        ? areaFraming.data?.total_area_reported -
          areaFraming.data?.total_area_registered
        : areaFraming.data?.total_area_reported -
          areaFraming.data?.total_area_registered
      : 0;
  }, [areaFraming]);

  const isError = useMemo(
    () =>
      proposal.isError ||
      client.isError ||
      ruralActivity.isError ||
      approvedCredit.isError,
    [
      client.isError,
      proposal.isError,
      ruralActivity.isError,
      approvedCredit.isError,
    ],
  );
  const isLoading = useMemo(
    () =>
      proposal.isLoading ||
      client.isLoading ||
      ruralActivity.isLoading ||
      approvedCredit.isLoading,
    [
      client.isLoading,
      proposal.isLoading,
      ruralActivity.isLoading,
      approvedCredit.isLoading,
    ],
  );

  const isNavigating = useMemo(
    () => typeof navigatingProps !== "undefined",
    [navigatingProps],
  );

  const replacePathWithParams: ReplacePathWithParams = useCallback(
    (path) => {
      if (!proposal.data?.id || !path) return "";

      return path.replace(":id", proposal.data.id.toString());
    },
    [proposal.data?.id],
  );

  const routing = useMemo(() => {
    return [
      PATH_APP.workflowContract.cliente,
      PATH_APP.workflowContract.areaFraming,
      PATH_APP.workflowContract.production,
      PATH_APP.workflowContract.warranties,
      PATH_APP.workflowContract.resume,
    ];
  }, []);

  const routesMatcher = useMemo((): RoutesMatcher => {
    return {
      client: matchPath(routing[0], location.pathname),
      areaFraming: matchPath(routing[1], location.pathname),
      production: matchPath(routing[2], location.pathname),
      warranties: matchPath(routing[3], location.pathname),
      resume: matchPath(routing[4], location.pathname),
    };
  }, [location.pathname, routing]);

  const handleLeftSide = useCallback((element) => {
    setLeftSide(element);
  }, []);

  const handleRightSide = useCallback((element) => {
    setRightSide(element);
  }, []);

  const handleNavigate: HandleNavigate = useCallback(
    (direction) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      handleLeftSide(undefined);
      handleRightSide(undefined);

      proposal.refetch();
      ruralActivity.refetch();

      const targetIndex = routing.findIndex((route) =>
        matchPath(route, location.pathname),
      );

      const movement = {
        previous: targetIndex - 1,
        next: targetIndex + 1,
      }[direction];

      setTimeout(() => {
        navigate(
          searchParams.get("next") ||
            replacePathWithParams(routing.at(movement)),
        );
      }, NAVIGATION_MOVEMENT_DELAY);
    },
    [
      handleLeftSide,
      handleRightSide,
      proposal,
      ruralActivity,
      routing,
      navigate,
      searchParams,
      replacePathWithParams,
      location.pathname,
    ],
  );

  const nextStep = useCallback(() => {
    handleNavigate("next");
  }, [handleNavigate]);

  const handleNavigating: HandleNavigating = useCallback((options) => {
    setNavigatingProps(options);
  }, []);

  const stateContextValue = useMemo((): StateContext => {
    return {
      client,
      proposal,
      routesMatcher,
      replacePathWithParams,
      areaFraming,
      ruralActivity,
      approvedCredit,
      remainingAreas,
      warranties,
      ruralActivityType,
    };
  }, [
    client,
    proposal,
    replacePathWithParams,
    routesMatcher,
    areaFraming,
    ruralActivity,
    approvedCredit,
    remainingAreas,
    warranties,
    ruralActivityType,
  ]);

  const updaterContextValue = useMemo((): UpdaterContext => {
    return {
      nextStep,
      handleLeftSide,
      handleRightSide,
      handleNavigating,
    };
  }, [nextStep, handleLeftSide, handleRightSide, handleNavigating]);

  useEffect(() => {
    const needVerify = !user?.marketplace?.verified;

    const inProgress =
      !user?.marketplace?.verified &&
      !user?.marketplace?.verification_feedback &&
      !user?.progressable?.missing_requirements?.includes(
        "verification_required?",
      );
    if (!inProgress && needVerify) {
      toggleAdvisorBankAccountAlert();
    }
  }, [user]);

  if (isError) {
    return (
      <Alert kind="error">
        Ocorreu um erro ou proposta não encontrada, tente novamente mais tarde.
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div className={workflowProposalStyles().base()}>
        <Skeleton height={380} />
        <Skeleton height={380} />
        <Skeleton height={380} />
      </div>
    );
  }

  return (
    <WorkflowProposalStateContext.Provider value={stateContextValue}>
      <WorkflowProposalUpdaterContext.Provider value={updaterContextValue}>
        <Middleware>
          <div ref={contextRef} className={workflowProposalStyles().base()}>
            <div className={workflowProposalStyles().aside()}>
              {leftSide}

              {isNavigating === false && !routesMatcher.resume && (
                <Wizard
                  title="Contratação de crédito"
                  pathParams={{ id: proposal.data?.id }}
                  steps={[
                    {
                      title: "Cadastro do cliente",
                      description:
                        "Informações básicas, endereços e dados bancários.",
                      path: PATH_APP.workflowContract.cliente,
                    },
                    {
                      title: "Enquadramento de áreas",
                      description:
                        "Dados e documentos para comprovações de áreas.",
                      path: PATH_APP.workflowContract.areaFraming,
                    },
                    {
                      title: "Produção beneficiada",
                      description:
                        "Informações sobre início do plantio e colheita.",
                      path: PATH_APP.workflowContract.production,
                    },
                    {
                      title: "Garantias",
                      description:
                        "Dados das garantias obrigatórias para esta operação.",
                      path: PATH_APP.workflowContract.warranties,
                    },
                  ]}
                >
                  <Card
                    classNames={{
                      base: "border border-neutral-light-2",
                    }}
                  >
                    <CardPreview
                      variant="outlined"
                      icon={<DocumentIcon />}
                      title={`#${proposal.data?.id} - ${ruralActivity.data?.harvest?.crop_cultivation?.name}`}
                      description={
                        approvedCredit.data?.approved_value
                          ? `Aprovado: ${formatToBRL(
                              approvedCredit.data?.approved_value,
                            )}`
                          : "Em andamento"
                      }
                      actions={{
                        primary: {
                          icon: <ExternalLinkIcon />,
                          onClick: () => {
                            navigate(`/propostas/${proposal.data?.id}`);
                          },
                        },
                      }}
                      classNames={{
                        base: "h-auto border-0 p-0 hover:shadow-none",
                      }}
                    />

                    <CellList
                      size="sm"
                      divider={[0]}
                      headers={[
                        { key: "name", label: "Para cliente:" },
                        { key: "document_number", label: "CPF:" },
                      ]}
                      cells={[
                        {
                          name: (
                            <p className="line-clamp-1">{client.data?.name}</p>
                          ),
                          document_number: (
                            <Copyable
                              classNames={{
                                base: "text-base font-normal",
                              }}
                            >
                              {formatToCPF(client.data?.document_number)}
                            </Copyable>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </Wizard>
              )}
            </div>

            <motion.div
              key={location.pathname}
              initial="initial"
              animate="in"
              variants={animate.page}
              transition={animate.transition}
              className={workflowProposalStyles().content()}
            >
              {isNavigating ? (
                <WorkflowLoader
                  {...navigatingProps}
                  onComplete={() => {
                    setNavigatingProps(undefined);
                    navigatingProps?.onComplete?.();
                  }}
                />
              ) : (
                <Outlet />
              )}
            </motion.div>

            <div className={workflowProposalStyles().aside()}>{rightSide}</div>
          </div>
        </Middleware>

        {advisorBankAccountAlertOpen && (
          <AdvisorBankAccountAlertDialog
            onClose={toggleAdvisorBankAccountAlert}
          />
        )}
      </WorkflowProposalUpdaterContext.Provider>
    </WorkflowProposalStateContext.Provider>
  );
}
