import { toast } from "@verde/utils";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PATH_APP } from "routes/paths";

import { useWorkflowProposalState } from ".";

interface WorkflowProposalMiddlewareProps {
  children: ReactNode;
}

export default function WorkflowProposalMiddleware({
  children,
}: WorkflowProposalMiddlewareProps) {
  const navigate = useNavigate();
  const {
    proposal,

    replacePathWithParams,
    remainingAreas,
    ruralActivityType,
    warranties,
  } = useWorkflowProposalState();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleContractStartFrom = useCallback(() => {
    const progress = proposal.data?.progressable;
    if (!progress?.steps_done.includes("client_data")) {
      navigate(replacePathWithParams(PATH_APP.workflowContract.cliente));
      setIsLoaded(true);
      return;
    }

    if (
      !progress?.steps_done.includes("properties_framing") ||
      remainingAreas > 0
    ) {
      navigate(replacePathWithParams(PATH_APP.workflowContract.areaFraming));
      setIsLoaded(true);
      return;
    }

    if (
      (!progress?.steps_done.includes("beneficiary_properties") &&
        ruralActivityType === "AGRICULTURA") ||
      (!progress?.steps_done.includes("livestock_about_production") &&
        ruralActivityType !== "AGRICULTURA")
    ) {
      navigate(replacePathWithParams(PATH_APP.workflowContract.production));
      setIsLoaded(true);
      return;
    }

    if (warranties.data?.some((warranty) => !warranty.progressable.is_done)) {
      navigate(replacePathWithParams(PATH_APP.workflowContract.warranties));
      setIsLoaded(true);
      return;
    }

    navigate(replacePathWithParams(PATH_APP.workflowContract.warranties));
    setIsLoaded(true);
    return;
  }, [
    proposal.data,
    navigate,
    replacePathWithParams,
    remainingAreas,
    ruralActivityType,
    warranties.data,
  ]);

  const handleRedirect = useCallback(() => {
    if (proposal.data?.active_state?.step === "DRAFT") {
      navigate(replacePathWithParams(PATH_APP.workflowProposal.financing));
      setIsLoaded(true);

      toast("Esta proposta ainda não foi finalizada.");
      return;
    }
    handleContractStartFrom();

    if (proposal.data?.active_state?.step !== "CONSOLIDATION") {
      navigate(replacePathWithParams(PATH_APP.proposals.details));
      setIsLoaded(true);
      return;
    }

    setIsLoaded(true);
  }, [proposal.data, navigate, replacePathWithParams, handleContractStartFrom]);

  useEffect(() => {
    if (
      !isLoaded &&
      !proposal.isLoading &&
      process.env.REACT_APP_ENVIRONMENT &&
      ["production", "beta"].includes(process.env.REACT_APP_ENVIRONMENT)
    ) {
      handleRedirect();
    } else {
      setIsLoaded(true);
    }
  }, [proposal.isLoading, isLoaded, handleRedirect]);

  if (!isLoaded) {
    return <p>carregando...</p>;
  }

  return <>{children}</>;
}
