import type { Advisor } from "@verde/entities";
import { Notification, useAuthContext } from "@verde/modules";
import { Box, Spinner } from "@verde/ui";
import {
  AdvisorBankAccountValidator,
  useAdvisorBankAccountValidator,
} from "components/advisor-bank-account";
import { type ReactNode, useEffect, useReducer } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PATH_APP, PATH_PAGE } from "routes/paths";

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user } = useAuthContext<Advisor>();
  const bankAccountValidator = useAdvisorBankAccountValidator();

  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT !== "staging" &&
      !isLoading &&
      isAuthenticated &&
      user &&
      user?.kind !== "SAAS_USER" &&
      user?.scheduled === false
    ) {
      navigate(PATH_APP.onboarding.appointment, {
        replace: true,
      });
    }
  }, [isLoading, isAuthenticated, user, navigate]);

  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="lg" />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: PATH_PAGE.auth.login,
          ...(location.pathname &&
            location.pathname !== "/" && {
              search: `?continue=${location.pathname}`,
            }),
        }}
      />
    );
  }

  return (
    <Notification.Provider
      enabled={user?.kind !== "SAAS_USER"}
      inject={{
        onAdvisorVerificationPending: bankAccountValidator.toggle,
      }}
    >
      {children}

      <AdvisorBankAccountValidator />
    </Notification.Provider>
  );
}
