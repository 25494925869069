import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import React from "react";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const Details = Loadable(lazy(() => import("./pages/details")));
const Edit = Loadable(lazy(() => import("./pages/details/tabs/edit")));

const clientsRoutes = {
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.account.root,
      element: <Details />,
      children: [
        {
          path: PATH_APP.account.root,
          element: <Edit />,
        },
      ],
    },
  ],
};

export default clientsRoutes;
