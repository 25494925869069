import { AlertDialog } from "@verde/compose-ui";
import { AlertCircleIcon, PlayIcon } from "@verde/icons";
import { Alert } from "@verde/ui-core";
import { memo } from "react";
import { useAdvisorBankAccountValidator } from ".";

interface AdvisorBankAccountAlertDialogProps {
  onClose: () => void;
}

function AdvisorBankAccountAlertDialog({
  onClose,
}: AdvisorBankAccountAlertDialogProps) {
  const { toggle } = useAdvisorBankAccountValidator();

  return (
    <AlertDialog
      alert={{
        isOpen: true,
        kind: "warning",
        confirmText: "Validar agora",
        confirmIcon: <PlayIcon />,
        title: "Não atrase seus recebimentos",
        onConfirm: () => {
          toggle();
        },
        cancelText: "Fechar",
        hideCancelButton: false,
        onCancel: () => {
          onClose();
        },
        description: (
          <div className="grid gap-2">
            <p className="text-lg font-semibold">
              Antes de continuar, confirme sua conta bancária antes que sua
              operação entre em desembolso.
            </p>
            <p className="text-base font-medium">
              Será necessário apenas uma vez e levará poucos minutos. Assim você
              garante que seus ganhos sejam devidamente recebidos.
            </p>

            <Alert
              icon={<AlertCircleIcon />}
              kind="warning"
              classNames={{
                base: "mt-4 border border-brand-yellow",
              }}
            >
              Lembre-se: a validação pode levar até 5 dias úteis.
            </Alert>
          </div>
        ),
      }}
    />
  );
}

export default memo(AdvisorBankAccountAlertDialog);
