import { Card, CardHeader } from "@verde/compose-ui";
import type { Advisor } from "@verde/entities";
import { ClockIcon, ExclamationTriangleIcon, SendIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, Button, Text } from "@verde/ui-core";
import { useAlert } from "@verde/utils";
import React, { useCallback, useMemo } from "react";
import { useAdvisorBankAccountValidator } from ".";
import type { AdvisorBankAccountAlertProps } from "./types";

function AdvisorBankAccountAlert({
  kind = "short",
}: AdvisorBankAccountAlertProps) {
  const { user } = useAuthContext<Advisor>();
  const { toggle } = useAdvisorBankAccountValidator();
  const alert = useAlert();
  const onClick = useCallback(async () => {
    if (user?.marketplace.verified) {
      await alert.execute({
        title: "Validação dados bancários",
        kind: "success",
        description: "Não se preocupe, dados já validados com sucesso!",
        hideCancelButton: true,
      });
    } else toggle();
  }, [toggle, user?.marketplace.verified, alert.execute]);

  const isDone = useMemo(() => {
    return user?.marketplace?.verified === true;
  }, [user]);

  const isShort = useMemo(() => {
    return kind === "short";
  }, [kind]);

  const inProgress = useMemo(() => {
    return (
      !user?.marketplace.verified &&
      !user?.marketplace.verification_feedback &&
      !user?.progressable?.missing_requirements?.includes(
        "verification_required?",
      )
    );
  }, [user?.marketplace, user?.progressable?.missing_requirements]);

  const textContent = useMemo(() => {
    return isShort
      ? "Confirme sua conta bancária antes que sua proposta entre em desembolso."
      : !inProgress
        ? `Confirme sua conta bancária antes do desembolso de suas operações.
          A confirmação pode levar até 5 dias pelo banco, por isso não deixe pra depois.`
        : "Aguarde até 5 para concluir a análise. Se necessário solicite a validação novamente ou entre em contato com seu gerente de relacionamento.";
  }, [isShort, inProgress]);

  if (isDone) return null;

  if (inProgress && kind === "short") return null;

  return (
    <Card
      classNames={{
        base: `border-brand-yellow border ${isShort ? "bg-neutral-light-pure" : "bg-off-yellow-bg"} `,
      }}
    >
      <CardHeader
        title={
          inProgress
            ? "Validação de dados em andamento"
            : "Não atrase seu recebimento"
        }
        icon={inProgress ? <ClockIcon /> : <ExclamationTriangleIcon />}
      />
      <Text className="font-medium text-neutral-dark-2">{textContent} </Text>
      {isShort && (
        <Alert kind="warning"> Validação pode levar até 5 dias úteis.</Alert>
      )}
      <Button onClick={onClick} icon={<SendIcon />} className="bg-brand-yellow">
        {inProgress ? "Solicitar novamente" : "Validar Agora"}
      </Button>
    </Card>
  );
}

export default React.memo(AdvisorBankAccountAlert);
